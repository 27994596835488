
import { defineComponent, computed} from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import LanguageMenu from "@/layout/header/partials/LanguageMenu.vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import config from "@/core/config/UtilConfig";

export interface User {
  email: string;
  password: string;
  token: string;
}

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    LanguageMenu
  },
  data(){
    return {
      userAuthenticated: store.getters.isUserAuthenticated,
      user: {} as User,
      email: "",
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : config.defaultLanguage
    }
  },
  mounted(){
    store.watch(function(){
        return store.getters.isUserAuthenticated
      }, () => {
        this.userAuthenticated = store.getters.isUserAuthenticated;
        this.user = store.getters.currentUser;
        this.email = this.user.email;
      }
    );

    window.addEventListener("locale-changed", (event) => {
      this.lang = localStorage.getItem("lang");
    });
  },
  setup(){
    const i18n = useI18n();

    const translate = (text) => {
      if (i18n.te(text)) {
        return i18n.t(text);
      } else {
        return text;
      }
    };

    i18n.locale.value = localStorage.getItem("lang") 
      ? (localStorage.getItem("lang") as string) : config.defaultLanguage;

    const languages = config.languages;
    
    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return languages[i18n.locale.value];
    });

    return {
      setLang,
      currentLanguage,
      currentLanguageLocale,
      languages,
      translate
    };
  }
});
