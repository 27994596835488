
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import config from "@/core/config/UtilConfig";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  props: {
    email: String
  },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    const translate = (text) => {
      if (i18n.te(text)) {
        return i18n.t(text);
      } else {
        return text;
      }
    };

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string) : config.defaultLanguage;

    const countries = config.countries;
    const languages = config.languages;

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "supply-login" }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return languages[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLanguageLocale,
      countries,
      languages,
      translate
    };
  },
});
