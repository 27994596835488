
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    }

    return {
      footerWidthFluid,
      translate
    };
  },
  methods: {
    refreshPage: function() {
      window.location.reload();
    }
  }
});
