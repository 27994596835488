
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";
import router from "@/router";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import config from "@/core/config/UtilConfig";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const logo = config.logo;
    const { t, te } = useI18n();
    const route = useRoute();

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      version,
      logo
    };
  },
  methods: {
    showWarning: function(link) {
      const changeLines = store.getters.getChangeLines;
      if(changeLines.length > 0) {
        Swal.fire({
          text: this.translate("doYouWantToChangeScreen"),
          icon: "warning",
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: this.translate("yes"),
          cancelButtonText: this.translate("no"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-warning",
            cancelButton: "btn"
          },
        }).then((result) => {
          if (result.isConfirmed) {
            router.push(link);
          }
        });
      } else {
        router.push(link);
      }
    }
  }
});
