
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import config from "@/core/config/UtilConfig";

export default defineComponent({
  name: "kt-language-menu",
  components: {},
  props: {
    email: String
  },
  setup() {
    const i18n = useI18n();
    
    const translate = (text) => {
      if (i18n.te(text)) {
        return i18n.t(text);
      } else {
        return text;
      }
    };

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string) : config.defaultLanguage;

    const countries = config.countries;
    const languages = config.languages;

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
      window.dispatchEvent(new CustomEvent('locale-changed', {
        detail: { storage: localStorage.getItem("lang") }
      }));
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return languages[i18n.locale.value];
    });

    return {
      setLang,
      currentLanguage,
      currentLanguageLocale,
      countries,
      languages,
      translate
    };
  },
});
