import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "symbol symbol-20px me-4" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
      return (_openBlock(), _createElementBlock("div", {
        key: country,
        class: "menu-item px-3"
      }, [
        _createElementVNode("a", {
          onClick: ($event: any) => (_ctx.setLang(country)),
          href: "#",
          class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage(country) }])
        }, [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("img", {
              class: "rounded-1",
              src: _ctx.languages[country].flag,
              alt: "metronic"
            }, null, 8, _hoisted_4)
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate(_ctx.languages[country].name)), 1)
        ], 10, _hoisted_2)
      ]))
    }), 128))
  ]))
}